import React from "react";
import "./Projects.scss";
import ExternalIcon from "../../Icons/ExternalIcon";
import GitHub from "../../Icons/GitHub";

const Projects = ({ projects }) => {
  return (
    <div className="Projects">
      <div className="Projects__container" id="projects">
        <h1>Projects</h1>
        {projects.map(
          ({
            id,
            label,
            title,
            details,
            technologie,
            technologie2,
            technologie3,
            technologie4,

            url,
            link,
            gh
          }) => (
            <div key={id} className="Projects__list">
              <div class="project-container">
                <div class="project">
                  <div class="project-content">
                    <div class="project-label">{label}</div>
                    <a
                      href={link}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <h4 class="project-title">{title}</h4>
                    </a>
                    <div class="project-details">
                      <p className="details">{details}</p>
                      <ul>
                        <li>{technologie}</li>
                        <li>{technologie2}</li>
                        <li>{technologie3}</li>
                        <li>{technologie4}</li>
                      </ul>
                      <div style={{ display: "flex" }}>
                        {" "}
                        <a
                          href={gh}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          {" "}
                          <GitHub />
                        </a>
                        <a
                          href={link}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          <ExternalIcon />
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                  <div class="project-img">
                    <img src={url} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <h4
        className="other__title"
        style={{
          fontSize: "2rem",
          color: "#ccd6f6",
          fontWeight: "bold"
        }}
      >
        Other projects
      </h4>
      <br />
      {/* <h4>Other projects</h4>
      {others.map(({ id, name, details, technologie }) => (
        <div className="other__projects" key={id}>
          <div>
            <div className="one__project">
              <header>
                <div className="other__icons">
                  <div className="folder__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      viewBox="0 0 60 60"
                    >
                      <title>Folder</title>
                      <path d="M57.49,21.5H54v-6.268c0-1.507-1.226-2.732-2.732-2.732H26.515l-5-7H2.732C1.226,5.5,0,6.726,0,8.232v43.687l0.006,0 c-0.005,0.563,0.17,1.114,0.522,1.575C1.018,54.134,1.76,54.5,2.565,54.5h44.759c1.156,0,2.174-0.779,2.45-1.813L60,24.649v-0.177 C60,22.75,58.944,21.5,57.49,21.5z M2,8.232C2,7.828,2.329,7.5,2.732,7.5h17.753l5,7h25.782c0.404,0,0.732,0.328,0.732,0.732V21.5 H12.731c-0.144,0-0.287,0.012-0.426,0.036c-0.973,0.163-1.782,0.873-2.023,1.776L2,45.899V8.232z M47.869,52.083 c-0.066,0.245-0.291,0.417-0.545,0.417H2.565c-0.243,0-0.385-0.139-0.448-0.222c-0.063-0.082-0.16-0.256-0.123-0.408l10.191-27.953 c0.066-0.245,0.291-0.417,0.545-0.417H54h3.49c0.38,0,0.477,0.546,0.502,0.819L47.869,52.083z"></path>
                    </svg>
                  </div>
                  <div className="link__icon">
                    <a href="">
                      {" "}
                      <GitHub />
                    </a>
                    <a href="">
                      {" "}
                      <ExternalIcon />
                    </a>
                  </div>
                </div>
                <h5 className="heading">{name}</h5>
                <div className="other__description">
                  <p>yoyoyoy</p>
                </div>
              </header>
            </div>
          </div>
        </div>
      ))} */}
    </div>
  );
};

Projects.defaultProps = {
  projects: [
    {
      id: "01",
      label: "Featured Project",
      title: "Slack Clone",
      details:
        "A Slack clone which gather the following features of the original app: public channels, direct messages possibility to add channels to favorite, change the sidebar theme and the profile picture, see  users status, a typing indicator, receive notifications when new messages are added to public channels.",
      technologie: "Firebase",
      technologie2: "React",
      technologie3: "Redux",
      url: "/images/slack-v2.png",
      link: "https://slack-v2.firebaseapp.com/",
      gh: "https://github.com/juliecarra/slack-v2"
    },
    {
      id: "02",
      label: "Featured Project",
      title: "Freelancer",
      details:
        "A social network which enables freelancers to connect with each other. The app has the following features: create a professional profile, add/delete posts, commentaries and likes. The app is also fetching the user's latest repositories thanks to the GitHub API.",
      technologie: "React Redux",
      technologie2: "Node.js MongoDB",
      technologie3: " Express.js",

      url: "/images/freelancer.png",
      link: "https://freelancer-front.herokuapp.com/",
      gh: "https://github.com/juliecarra/Freelancer"
    },
    {
      id: "03",
      label: "Featured Project",
      title: "Hizzy.Learn",
      details:
        "An e-learning platform which aims at allowing people to learn programmation through courses and videos. The app gather the following features: create a profile and choose a level and a course, watch tutorials and keep track of your progression.",
      technologie: "Node.js",
      technologie2: "Express.js",
      technologie3: "MongoDB",
      technologie4: "HBS",

      url: "/images/hizzy-learn.png",
      link: "http://hizzy-learn.herokuapp.com/",
      gh: "https://github.com/juliecarra/Hizzy-Learn"
    }
  ]
};

export default Projects;

const education = {
  ironhack: {
    name: "Ironhack",
    date: "August 2019 - October 2019",
    text: "An intensive 9 week bootcamp in web development ",
    text2: "split up in 3 modules and 3 projects.",
    text3: "The first module focused on HTML, CSS and JS",
    text3b: "and building a game.",
    text4: "The second module focused on Express.js,",
    text5: " Node.js, MongoDB for database, Handlebars",
    text6: " as a view engine and building a back-end app.",
    text7: "The last module focused on React as a framework",
    text8: "and building a fullstack application. "
  },
  42: {
    id: "02",
    name: "42",
    date: "July 2019",
    text: "An intensive 4 week pool in programming.",
    text2: "Each day focused on learning a particular concept",
    text2b: "of the C language and solving a serie of exercices.",
    text3: "Here are the concepts that I've learnt during ",
    text3b: "these 4 weeks:",
    text4: "The Unix/Linux operating system with Shell.",
    text7: "The C programming language with:",
    text8: "Data types, Pointers, Arrays, Memory management,",
    text9: "Libraries, Recursion and Binary Trees."
  },
  ucp: {
    id: "03",
    name: "University of Cergy-Pontoise",
    date: "2014-2017",
    text: "Bachelor’s degree in Applied Foreign Languages:",
    text2: "English and Spanish with a specialization in ",
    text2b: "Culture and Tourism.",
    text3: "During this BA, I've mainly followed courses in:",
    text4: "Translation, Negotiation, History & Civilization, ",
    text5: " Law, Marketing.",
    text7: "Also with my specialization in Culture and Tourism,",
    text8: "I've followed courses in: Sociology, Anthropology",
    text9: "...",
    technologies: "yu yu oh"
  }
};

export default education;

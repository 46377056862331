import React from "react";
import "./Email.scss";

const Email = ({ email }) => {
  return (
    <div className="Email">
      <div className="Email__container">
        <a href={`mailto:${email}`} className="Email__link">
          {email}
        </a>
      </div>
    </div>
  );
};

Email.defaultProps = {
  email: "julie.carra@live.fr"
};

export default Email;

import React, { useState } from "react";
import "./Education.scss";
import education from "./education.js";

const Education = () => {
  const [clicked, setClicked] = useState("ironhack");

  function handleClick(e) {
    if (!e.target.id) return;
    const clicked = e.target.id;
    const schools = document.querySelectorAll(".one_education");
    schools.forEach(school => {
      school.classList.remove("active_school");
      if (school.id === clicked) {
        school.classList.add("active_school");
      }
    });

    setClicked(clicked);
  }

  return (
    <div className="Education">
      <div className="Education__container" id="education">
        <h1>Education</h1>
        <div className="Education__selected">
          <h2>{education[clicked].name}</h2>
          <br />
          <h3>{education[clicked].date}</h3>
          <br />
          <h4>{education[clicked].text}</h4>
          <h4>{education[clicked].text2}</h4>
          <h4>{education[clicked].text2b}</h4>
          <br />
          <h4>{education[clicked].text3}</h4>
          <h4>{education[clicked].text3b}</h4>
          <br />
          <h4>{education[clicked].text4}</h4>
          <h4>{education[clicked].text5}</h4>
          <h4>{education[clicked].text6}</h4>
          <br />
          <h4>{education[clicked].text7}</h4>
          <h4>{education[clicked].text8}</h4>
          <h4>{education[clicked].text9}</h4>
        </div>

        <div class="scrollbar">
          <span class="scrollbar_line"></span>
          <span class="circle">
            <i class="fas">
              <img
                src="/images/ironhack.png"
                width="50px"
                height="50px"
                alt=""
                className="one_education"
                onClick={handleClick}
                id="ironhack"
              />
            </i>
          </span>

          <span class=" circle">
            <i class="fas ">
              {" "}
              <img
                src="/images/42.png"
                className="one_education"
                width="50px"
                height="50px"
                alt=""
                id="42"
                onClick={handleClick}
              />
            </i>
          </span>
          <span class=" circle">
            <i class=" fas ">
              {" "}
              <img
                src="/images/ucp.svg"
                className="one_education"
                width="50px"
                height="50px"
                alt=""
                id="ucp"
                onClick={handleClick}
              />
            </i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Education;

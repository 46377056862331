import React, { useState } from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { Navbar, Button } from "react-bulma-components";
import SmoothScrolling from "./SmoothScrolling";
import "./Nav.css";

const Nav = () => {
  const [active, setActive] = useState(false);

  const handleScroll = () => {
    SmoothScrolling.scrollTo("about");
  };

  const handleScroll2 = () => {
    SmoothScrolling.scrollTo("education");
  };

  return (
    <div className="Nav">
      <Navbar active={active}>
        <Navbar.Brand>
          <Navbar.Item
            renderAs="a"
            href="/"
            style={{ fontSize: "20px", marginLeft: "35px" }}
          >
            {/* JC <span style={{ color: "#ff2e97" }}>.</span> */}
            <i
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "rgb(204, 214, 246)"
              }}
              class="fas fa-home"
            ></i>
          </Navbar.Item>
          {active ? (
            <Navbar.Burger
              className="trigger-menu"
              onClick={() => setActive(false)}
            />
          ) : (
            <Navbar.Burger
              className="trigger-menu"
              onClick={() => setActive(true)}
            />
          )}
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container position="end">
            <span
              style={{ marginTop: "25px", fontSize: "13px", color: "#ff2e97" }}
            >
              01.
            </span>{" "}
            <Navbar.Item
              href="#about"
              onClick={handleScroll}
              style={{ transitionDelay: "0ms" }}
            >
              About
            </Navbar.Item>
            <Navbar.Divider />
            <span
              style={{ marginTop: "25px", fontSize: "13px", color: "#ff2e97" }}
            >
              02.
            </span>{" "}
            <Navbar.Item
              href="#education"
              style={{ transitionDelay: "100ms" }}
              onClick={handleScroll2}
            >
              Education
            </Navbar.Item>
            <Navbar.Divider />
            <span
              style={{ marginTop: "25px", fontSize: "13px", color: "#ff2e97" }}
            >
              03.
            </span>
            <Navbar.Item href="#projects" style={{ transitionDelay: "200ms" }}>
              Projects
            </Navbar.Item>
            <Navbar.Divider />
            <span
              style={{ marginTop: "25px", fontSize: "13px", color: "#ff2e97" }}
            >
              04.
            </span>{" "}
            <Navbar.Item href="#" style={{ transitionDelay: "300ms" }}>
              Contact
            </Navbar.Item>
            <Navbar.Divider />
            <Button>Resume</Button>
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
    </div>
  );
};

export default Nav;

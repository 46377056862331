import React from "react";
import "./Hero.scss";

const Hero = ({ greetings, id, role }) => {
  return (
    <div className="Hero">
      <div className="Hero__container">
        <p className="greetings">{greetings}</p>
        <h2>
          {id}
          <span>.</span>
        </h2>

        <h3>
          {role}
          <span>.</span>
        </h3>

        <div>
          <p>I'm a web developer based in Paris, FR focusing in building </p>
          <p>web applications with a passion for front-end technologies. </p>
        </div>
      </div>
    </div>
  );
};

Hero.defaultProps = {
  greetings: "Hello, my name is",
  id: "Julie Carra",
  role: "I'm a web developer"
};
export default Hero;

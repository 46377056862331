import React from "react";
import Icons from "../Icons/Icons";
import "./SocialMedia.scss";

const SocialMedia = ({ socialMedia }) => {
  return (
    <div className="SocialMedia">
      <ul className="SocialMedia__list">
        {socialMedia &&
          socialMedia.map(({ id, name, url }) => (
            <li key={id}>
              <a
                className="SocialMedia__link"
                href={url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label={name}
              >
                <Icons className="icons" name={name} />
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};
SocialMedia.defaultProps = {
  socialMedia: [
    {
      id: 1,
      name: "GitHub",
      url: "https://github.com/juliecarra"
    },
    {
      id: 2,
      name: "Linkedin",
      url: "https://www.linkedin.com/in/juliecarra"
    }
  ]
};
export default SocialMedia;

import React from "react";
import "./About.scss";

const About = () => {
  return (
    <div className="About">
      <div className="About__container" id="about">
        <h1>About Me</h1>
        <div>
          <section>
            <p style={{ marginLeft: "-95px" }}>
              I'm Julie, a web developer based in Paris,FR.
            </p>
            <br />
            <p style={{ marginLeft: "-106px" }}>
              Following studies in foreign languages and{" "}
            </p>
            <p style={{ marginLeft: "-135px" }}>
              tourism, I've decided to take up a new <br />
              <p style={{ marginLeft: "13px" }}>
                challenge by learning web development.{" "}
              </p>
              <br />
            </p>{" "}
            <p style={{ marginLeft: "-67px" }}>
              Recently graduated from the{" "}
              <a
                href="https://www.ironhack.com/en"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Ironhack
              </a>{" "}
              bootcamp, <br />
            </p>
            <p style={{ marginLeft: "-87px" }}>
              I am now looking for a job as a web developer.
            </p>
            <br />
            <p style={{ marginLeft: "-105px" }}>
              Here are the technologies I've worked with:
            </p>
            <ul>
              <li>HTML/HBS </li>
              <li>React</li>
              <li>Git</li>
              <li>CSS/SASS</li>
              <li>Redux</li>
              <li>Netifly</li>
              <li>JavaScript(ES6)</li>
              <li>Node.js</li>
              <li>Heroku</li>
              <li>C</li>
              <li>Express.js</li>
              <li>Firebase</li>
              <li>Postman</li>
              <li>MongoDB</li>
              <li>API</li>
            </ul>
          </section>
          <div class="container__image">
            <a
              href="https://github.com/juliecarra"
              target="_blank"
              rel="nofollow noopener noreferrer"
              className="image__link"
            >
              <img
                src="https://about.canva.com/wp-content/uploads/sites/3/2016/08/logos-1.png"
                alt=""
                style={{ filter: "#ff2e97" }}
                className="me"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

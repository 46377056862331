import React from "react";
import SocialMedia from "../SocialMedia/SocialMedia";
import Email from "../Email/Email";
import Hero from "../Hero/Hero";
import About from "../pages/About/About";
import Education from "../Education/Education.jsx";
import Projects from "../pages/Projects/Projects";
import OtherProjects from "../pages/Projects/OtherProjects";

const Layout = () => {
  return (
    <div>
      <div>
        <SocialMedia />
        <Email />
        <div>
          <Hero />
          <About />
          <Education />
          <Projects />
          <OtherProjects />
        </div>
      </div>
    </div>
  );
};

export default Layout;

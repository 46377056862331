import React from "react";
import Nav from "./components/Nav/Nav";
import Home from "./components/pages/Home/Home";
import "./App.css";
import Layout from "./components/Layout/Layout";
import { Hero } from "react-bulma-components";

const App = () => {
  return (
    <div className="App">
      <Nav />
      {/* <Home /> */}
      <Layout />
      <Hero />
    </div>
  );
};

export default App;
